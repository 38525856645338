// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ProductCard from "../components/productcard"
import SEO from "../components/seo"

function Projects(props: PageProps) {
  return (
    <Layout>
      <SEO title="Projects" />

      <Link
        to={`https://www.everbutton.com/`}
        className="product-card"
      >
        <StaticImage
          className="product-card-image "
          src="../images/everbutton.png"
          alt="Moon Letter Screenshot" />
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Everbutton</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">EverButton lets you add modern payment buttons to your website simply by copy-pasting. No coding required!</p>
        </div>
      </Link>

      <Link
        to={`http://moonletter.vasusen.com/`}
        className="product-card"
      >
        <StaticImage
          className="product-card-image "
          src="../images/moonletter.png"
          alt="Moon Letter Screenshot" />
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Moon Letter</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">With moonletter, you can send messages to your friends that are only visible in a certain moonlight. This is my tribute to The Hobbit.</p>
        </div>
      </Link>

      <Link
        to="/"
        className="product-card"
      >
        <StaticImage
          className="product-card-image border-b-2 border-gray-100  dark:border-gray-400"
          src="../images/adjective_for.png"
          alt="Moon Letter Screenshot" />
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Adjective For</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Adjectivefor.com lets you search for commonly used words that describe any given word.</p>
        </div>
      </Link>
    </Layout>
  )
}

export default Projects
